<template>
  <v-card>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div style="width: 100%;">
        <v-card-subtitle v-text="title" />
        <v-card-title
          class="pt-0 justify-center justify-md-start text-h5 text-sm-h3 text-md-h4"
          v-text="number"
        />
      </div>
      <v-avatar
        class="ma-2 mt-10 pt-5 d-none d-md-flex d-lg-flex"
        size="48px"
        tile
      >
        <v-icon
          :color="tIconColor"
          size="40"
          v-text="tIcon"
        />
      </v-avatar>
    </div>
    <v-divider />
    <v-card-actions class="pa-4">
      <v-icon
        small
        :color="bIconColor"
      >
        {{ bIcon }}
      </v-icon>
      <span class="caption font-italic">&nbsp;{{ bText }}</span>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'number', 'tIcon', 'tIconColor', 'bIcon', 'bIconColor', 'bText']
}
</script>

<style lang="scss" scoped>

</style>
